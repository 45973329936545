<template>
    <div :class="{mobile: isMobile}">
        <div class="top mb-7">
            <div class="title">{{ $t("scroe_task_center") }}</div>
            <div class="right">
                <div class="points">
                    <div class="num1">{{$t("pointsCenter_exchange")}} {{currentPoints.toFixed(6)}}</div>
                    <div class="progress" :style="{width:progressWidth+'px'}">
                        <div class="cg" :style="{width:currentProgress+'px'}"></div>
                    </div>
                    <div class="num2">{{ $t("pointsCenter_num2") }}：{{scoreLimit}} {{ $t("pointsCenter_Points") }}  ({{scoreLimit*scoreRate}} UPTICK)</div>
                </div>
                <div v-if="currentPoints>=scoreLimit" class="btn-claim" @click="exchange"><span>{{ $t("admin_activity_btn_claim") }}</span></div>
                <div v-else class="btn-claim btn-claim-grey"><span>{{ $t("admin_activity_btn_claim") }}</span></div>
                <img src="@/assets/icons/icon_claimhistory.png" @mouseenter="historymoused()" @mouseleave="historyleave()" width="23" height="23" class="ml-4" alt="" @click="toHistroy">
                <div class="showHistroy d-flex flex-row align-center" v-if="isShowHistroy" >{{$t('score_histroy')}}</div>
            </div>
        </div>
        <div class="number">
            <div class="item">
                <div class="back">
                    <span class="s1">{{ $t("scroe_task_title") }}</span>
                    <span class="s2">{{totalScore.toFixed(6)}}</span>
                </div>
            </div>
            <div class="item">
                <div class="back">
                    <span class="s1">{{ $t("scroe_task_consumer") }}</span>
                    <div class="d-flex flex-row">
                         <span class="s2">{{scroe_task_consumer}}</span>
                         <img
                          @mouseenter="moused()"
                        @mouseleave="leave()"
                     style="width: 17px; height: 19px; margin-top:10px;margin-left:10px; cursor: pointer;"
                     src="@/assets/icons/icon_dbz.png"
                     alt
              />
                    </div>
                           <div class="explain " v-if="isshow && iconBalance &&  iconBalance.length>0">
                    <div class="all">
                         <div class="iris " v-if="irisOrder">
                        <span>{{ $t("scroe_iris_order") }} {{ irisOrder }}</span>
                    </div>
                    <div class="iris mt-3" v-if="atomOrder">
                        <span>{{ $t("scroe_atom_order") }} {{atomOrder}}</span>
                    </div>
                    <div class="iris mt-3" v-if="usdcOrder">
                        <span>{{ $t("scroe_usdc_order") }} {{usdcOrder}}</span>
                    </div>
                    </div>
                   
              </div>
                
                </div>
            </div>
            <div class="item">
                <div class="back">
                    <span class="s1">{{ $t("scroe_task_creator") }}</span>
                    <span class="s2">{{scroe_task_creator}}</span>
                </div>
            </div>
        </div>
        <div class="list">
            <div class="row" v-for="(item, idx) in list" :key="idx">
                <span class="item s1">{{item.description}} {{ item.tokenType ?'('+item.tokenType+')':''}}</span>
                <span class="item s2">{{timestampToDateTime(item.createTime)}}</span>
                <span class="item s3">{{item.score>0?'+':''}}{{item.score}}</span>
            </div>
        </div>
        <loading :isShowLoading="isShowLoading" ></loading>

        <promptBox ref="confirm" @commit="exchangeConfirm"></promptBox>
        <uToast ref="toast"></uToast>
    </div>
</template>

<script>
    import api from "@/api";
    import Loading from "@/components/loading.vue";
    import {getAddress } from "../../keplr/wallet";
    
    export default {
        components: { Loading },
        data() {
            return {
                // scroe_task_title: 0,
                scroe_task_consumer: 0,
                scroe_task_creator: 0,
                list: [
              
                ],
                isShowHistroy:false,
                pageNumber: 1,
                totalPage: 0,
                
                isShowLoading: false,
                loadingTimer: 0,

                totalScore: 0,
                scoreLimit: 1,
                currentPoints: 0,
                currentProgress: 0,

                scoreRate: 0,
                progressWidth: 0,
                isshow:false,
                iconBalance:[],
                irisOrder:0, 
                atomOrder:0, 
                usdcOrder:0,
                lang:'',
                uptickAddress:'',
                IRISAddress:''
            }
        },
        computed: {
            isMobile: function() {
                let mobile = this.$vuetify.breakpoint.mobile;
                return mobile;
            }
        },
        mounted() {
            window.eventBus.$on('LangChange', this.onLangChange);
            window.onscroll = this.onScroll;
            
            this.progressWidth = this.isMobile ? 240 : 403;

            this.init();
        },
        methods: {
            async init() {
                let res1 = await api.home.getMyInfo({
                    did: this.$store.state.did
                });
                this.totalScore = parseFloat(res1.data.totalScore || 0);

                let res2 = await api.home.scoreReward({
                    userAddress: this.$store.state.did,
                    action: "BUY"
                });
                this.scroe_task_consumer = parseFloat(res2.data.score || 0);

                let res3 = await api.home.scoreReward({
                    userAddress: this.$store.state.did,
                    action: "MINT"
                });
                this.scroe_task_creator = parseFloat(res3.data.score || 0);

                let res4 = await api.home.getFee();
                this.scoreLimit = parseFloat(res4.data.ScoreExchangeLimit || 0);
                this.scoreRate = parseFloat(res4.data.ScoreExchangeRate || 0);
                this.currentPoints = parseFloat(res1.data.currentScore || 0);

                this.getList();

                this.currentProgress = this.progressWidth / this.scoreLimit * this.currentPoints;

                // 获取uptick地址
                 let uptickObj = await getAddress(0);
                 this.uptickAddress = uptickObj.address
                 console.log("uptickObj",uptickObj);
                 debugger

                let orderRecord = await api.home.orderRecord({
                 userAddress: this.$store.state.did,
            
            })



            this.iconBalance = orderRecord.data
               let env =  process.env.VUE_APP_IRIS_CHAIN_ID
       if(env == 'nyancat-9'){
             for(let i=0 ; i< this.iconBalance.length;i++){  
                if(this.iconBalance[i].coinDenom == "unyan"){
                    this.irisOrder = this.iconBalance[i].score 
                }else if(this.iconBalance[i].coinDenom == "ibc/733D9E6DE683B9726B2C6A6460566B11FAF4C075DC08861685F269537D43E24D")
                {
                    this.atomOrder = this.iconBalance[i].score 
                }else if(this.iconBalance[i].coinDenom == "ibc/5C465997B4F582F602CD64E12031C6A6E18CAF1E6EDC9B5D808822DC0B5F850C")
                {
                    this.usdcOrder = this.iconBalance[i].score 
                }
            }
       }else{
             for(let i=0 ; i< this.iconBalance.length;i++){  
                if(this.iconBalance[i].coinDenom == "uiris"){
                    this.irisOrder = this.iconBalance[i].score 
                }else if(this.iconBalance[i].coinDenom == "ibc/27394FB092D2ECCD56123C74F36E4C1F926001CEADA9CA97EA622B25F41E5EB2")
                {
                    this.atomOrder = this.iconBalance[i].score 
                }else if(this.iconBalance[i].coinDenom == "ibc/A5A5EBF3B7A180FBA2A3CE133153F5B904AA8B085691F66A15BE8156B6722652")
                {
                    this.usdcOrder = this.iconBalance[i].score 
                }
            }
       }
          
             
     
            //  for(let i=0 ; i< this.iconBalance.length;i++){  
            //     if(this.iconBalance[i].tokenType == "UPTICK"){
            //         this.uptickOrder = this.iconBalance[i].score 
            //     }else if(this.iconBalance[i].tokenType == "ATOM")
            //     {
            //         this.atomOrder = this.iconBalance[i].score 
            //     }else if(this.iconBalance[i].tokenType == "IRIS"){
            //         this.irisOrder = this.iconBalance[i].score 
            //     }
            // }
       
            },
            async getList() {
                let res4 = await api.home.scoreRecord({
                    userAddress: this.$store.state.did,
                    pageSize: 10,
                    pageNumber: this.pageNumber,
                    lang:this.lang
                });
                this.list = [...this.list, ...res4.data.list];
                this.totalPage = res4.data.totalPage;
            },
            onScroll() {
                let scrollTop = document.documentElement.scrollTop||document.body.scrollTop;
                let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
                let scrollHeight = document.documentElement.scrollHeight||document.body.scrollHeight;
                
                if (!this.isShowLoading && scrollTop + windowHeight >= scrollHeight && this.totalPage > this.pageNumber) {
                    clearTimeout(this.loadingTimer);
                    this.loadingTimer = setTimeout(() => {
                        this.isShowLoading = true;
                        this.pageNumber++;
                        this.getList();
                    }, 400);
                } else {
                    this.isShowLoading = false;
                }
            },
            onLangChange(language) {
                this.lang = language
                this.list = [];
                this.pageNumber = 1;
                this.getList();
            },
             moused() {
                            console.log("22222");

                this.isshow = true;
                },
                leave() {
                    console.log("33333");
                    this.isshow = false
                
                },
                historymoused(){
                    this.isShowHistroy = true
                },
                 historyleave(){
                     this.isShowHistroy = false

                },
                toHistroy(){
                    this.$router.push({name:'ClaimHistroy'})
                },
            exchange() {
                this.$refs.confirm.show(this.$t("pointsCenter_exchange")+" "+this.currentPoints+" "+this.$t("pointsCenter_Points")+" ");
            },
            async exchangeConfirm() {

                 // 解决网络问题 导致切换钱包没有刷新页面 实时获取钱包地址
				let accountInfo = await getAddress(0);
				let irisInfo =  await getAddress(1)
				this.IRISAddress = irisInfo.address
                this.uptickAddress = accountInfo.address
                let res1 = await api.home.scoreExchange({
                     userAddress: this.IRISAddress,
                    receiveAddress: this.uptickAddress
                });
                this.$refs.toast.show("success", res1.data);
                this.init();
            }
        }
    }
</script>

<style lang="scss" scoped>
    .top {
        display: flex;
        margin-bottom: 10px;
        .title {
            width: 300px;
            font-family: Helvetica;
            font-weight: bold;
            font-size: 25px;
            font-stretch: normal;
            letter-spacing: 0px;
            color: #270645;
        }
        .right {
            position: relative;
            margin-left: auto;
            display: flex;
            align-items: center;
            .points {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                .num1 {
                      font-family: Helvetica;
                      font-weight: bold;
                    font-size: 14px;
                    color: #924eff;
                }
                .num2 {
                     font-family: Helvetica;
                    font-size: 12px;
                    color: #270645;
                }
                .progress {
                    width: 403px;
                    height: 6px;
                    background-image: linear-gradient(
                        #766983, 
                        #766983), 
                    linear-gradient(
                        #f1f0f2, 
                        #f1f0f2);
                    background-blend-mode: normal, 
                        normal;
                    border-radius: 3px;
                    margin: 3px 0px;
                    overflow: hidden;
                    .cg {
                        height: 6px;
                        background-image: linear-gradient(90deg, 
                            #d300fd 0%, 
                            #a439fe 26%, 
                            #7471ff 52%, 
                            #00fdcf 100%), 
                        linear-gradient(
                            #f1f0f2, 
                            #f1f0f2);
                        background-blend-mode: normal, 
                            normal;
                        border-radius: 3px;
                    }
                }
            }
            .showHistroy{
                    position: absolute;
                    z-index: 100;
                    top: 48px;
                    right: 0;
                    width: 110px;
                    height: 41px;
                    background-color: #270645;
                    border-radius: 3px;
                	font-family: Helvetica;
                    font-size: 12px;
                    font-weight: normal;
                    font-stretch: normal;
                    line-height: 20px;
                    letter-spacing: 0px;
                    color: #ffffff;
                    justify-content: center;
            }
            .btn-claim {
                width: 121px;
                height: 41px;
                background-color: #270645;
                border-radius: 20px;
                text-align: center;
                margin-left: 15px;
                user-select: none;
                cursor: pointer;

                span {
                    font-family: Helvetica;
                      font-weight: bold;
                    font-size: 15px;
                    line-height: 41px;
                    color: #ffffff;
                }
            }
            .btn-claim-grey {
                background-color: #766983;
                cursor: unset !important;
            }
        }
    }
    

    .number {
        display: flex;
        justify-content: space-between;
        height: 160px;

        .item {
            width: 391px;
            height: 116px;
            background-image: linear-gradient(0deg,
                    #d202fd 0%,
                    #a538fe 26%,
                    #776eff 51%,
                    #01facd 100%),
                linear-gradient(#270645,
                    #270645);
            background-blend-mode: normal,
                normal;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 18px;
            position: relative;

            .back {
                position: absolute;
                left: 2px;
                top: 2px;
                right: 2px;
                bottom: 2px;
                background-color: #FFFFFF;
                border-radius: 18px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .s1 {
                     font-family: Helvetica;
                      font-weight: bold;
                    font-size: 14px;
                    color: #270645;
                }

                .s2 {
                     font-family: Helvetica;
                      font-weight: bold;
                    font-size: 25px;
                    color: #924eff;
                }
            }
                   .explain {
      width: 236px;
      background-image: linear-gradient(
		#ffffff, 
		#ffffff), 
	linear-gradient(
		#6f58d9, 
		#6f58d9);
        background-blend-mode: normal, 
		normal;
	box-shadow: 0px 0px 9px 0px 
		rgba(0, 0, 0, 0.09);
      border-radius: 5px;
      position: absolute;
      top: 80px;
      right: -70px;
      z-index:1;
      .all{
          margin: 17px;
           .iris{
         display: flex;
        flex-direction: row; 
        span{
        font-family: Helvetica;
        font-size: 13px;
        font-weight: bold;
        font-stretch: normal;
        color: #270645;
        }
      }

      }
     
    }
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;

        .row {
            width: 100%;
            height: 61px;
            background-image: linear-gradient(#ffffff,
                    #ffffff),
                linear-gradient(#6f58d9,
                    #6f58d9);
            background-blend-mode: normal,
                normal;
            box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.09);
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 20px;

            .s1 {
                width: 43%;
                max-width: 43%;
                  font-family: Helvetica;
                font-weight: bold;
                font-size: 14px;
                color: #270645;
                font-weight: bold;
            }

            .s2 {
                width: 43%;
                max-width: 43%;
                  font-family: Helvetica;
                
                font-size: 12px;
                color: #270645;
            }
            .s3 {
                width: 3%;
                max-width: 3%;
                  font-family: Helvetica;
                      font-weight: bold;
                font-size: 14px;
             
                color: #924eff;
            }
        }
    }

    .mobile {
        margin-top: -20px;
        .top {
            flex-direction: column;
            .btn-claim {
                width: 100px;
            }
        }
        
        .title {
              font-family: Helvetica;
            font-weight: bold;
            font-size: 15px;
            color: #270645;
            line-height: 80px;
        }

        .number {
            height: 90px;

            .item {
                width: 115px;
                height: 65px;

                .back {
                    .s1 {
                        font-size: 9px;
                        white-space: nowrap;
                    }

                    .s2 {
                        font-size: 14px;
                    }
                }
            }
        }

        .list {
            .row {
                width: 100%;

                .s2 {
                    width: 40%;
                }
                
                .s3 {
                    width: 10%;
                    max-width: 10%;
                }
                
            }
        }
    }
</style>
